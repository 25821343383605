import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

import "./Upload.css";

const endpoint = "/api/upload";
const hoursArray = Array.from(Array(24), (_, i) => `${i}`.padStart(2, "0"));
const minutesArray = Array.from(Array(60), (_, i) => `${i}`.padStart(2, "0"));

class Upload extends Component {
	constructor(props) {
		super(props);
		const now = new Date();
		this.state = {
			selectedFile: null,
			uploading: false,
			progress: 0,
			hour: this.props.match.params.hour || now.getHours(),
			minute: this.props.match.params.minute || now.getMinutes(),
			toHour: false,
			termsChecked: false,
			fileName: "no file selected"
		};
		this.state.hour = `${this.state.hour}`.padStart(2, "0");
		this.state.minute = `${this.state.minute}`.padStart(2, "0");
	}

	fileChosen = event => {
		this.setState({ selectedFile: event.target.files[0], progress: 0 });
		if (event.target.files[0]) {
			this.setState({ fileName: event.target.files[0].name });
		} else {
			this.setState({ fileName: "no file selected" });
		}
	};

	selectHour = event => {
		this.setState({ hour: event.target.value });
	};

	selectMinute = event => {
		this.setState({ minute: event.target.value });
	};

	checkTerms = event => {
		this.setState({ termsChecked: !!event.target.checked });
	};

	doUpload = event => {
		if (!this.state.selectedFile) {
			return;
		}
		this.setState({ uploading: true });
		const data = new FormData();
		data.append("file", this.state.selectedFile, this.state.selectedFile.name);
		data.append("hour", this.state.hour);
		data.append("minute", this.state.minute);

		axios
			.post(endpoint, data, {
				onUploadProgress: ProgressEvent => {
					this.setState({
						progress: (ProgressEvent.loaded / ProgressEvent.total) * 100
					});
				}
			})
			.then(res => {
				this.setState({ uploading: false });
				if (res && res.data === "EXISTS") {
					this.setState({ progress: 0 });
					alert(`This time slot is already taken, please try another. 
Available slots can be found under All Hours.`);
				} else {
					this.setState({ toHour: true });
					this.props.refreshStatus && this.props.refreshStatus();
				}
			})
			.catch(err => {
				console.error("upload error", err);
				this.setState({ uploading: false, progress: 0 });
				alert("Error uploading, try again later.");
			});
	};

	render() {
		if (this.state.toHour) {
			return <Redirect to={`/day/${this.state.hour}`} />;
		}
		return (
			<div>
				<div className="Upload File">
					<input
						type="file"
						id="file"
						accept="image/*"
						onChange={this.fileChosen}
					/>
					<label
						htmlFor="file"
						className={`FileLabel ${
							this.state.selectedFile ? "FileChosen" : ""
						}`}
					>
						<span>{this.state.fileName}</span>
					</label>
				</div>
				<div className="Upload Time">
					<label htmlFor="HourSelect" className="TimeLabel">
						Time:{" "}
					</label>
					<div className="SelectContainer">
						<select
							id="HourSelect"
							value={this.state.hour}
							onChange={this.selectHour}
						>
							{hoursArray.map(h => (
								<option key={h} value={h}>
									{h}
								</option>
							))}
						</select>
						<svg className="SelectArrow" viewBox="0 0 37 25">
							<polygon points="18.62,22.64 2.36,6.37 6.37,2.36 18.62,14.62 30.63,2.6 34.64,6.61 " />
						</svg>
					</div>
					<label htmlFor="MinuteSelect" className="TimeLabel">
						:{" "}
					</label>
					<div className="SelectContainer">
						<select
							id="MinuteSelect"
							value={this.state.minute}
							onChange={this.selectMinute}
						>
							{minutesArray.map(m => (
								<option key={m} value={m}>
									{m}
								</option>
							))}
						</select>
						<svg className="SelectArrow" viewBox="0 0 37 25">
							<polygon points="18.62,22.64 2.36,6.37 6.37,2.36 18.62,14.62 30.63,2.6 34.64,6.61 " />
						</svg>
					</div>
				</div>
				<div className="Terms">
					<input
						type="checkbox"
						checked={this.state.termsChecked}
						onChange={this.checkTerms}
						id="TermsChecbox"
					/>
					<label htmlFor="TermsChecbox">
						By uploading you are contributing to a public artwork. Your image
						will only be used for this particular work, and the promotion of it. 
						By uploading you declare to own the copyrights of the uploaded image and 
						have the consent of any person portrayed. 
						You are free to remove your image at anytime, by{" "}
						<a href="mailto:mail@helmutsmits.nl">email</a> request.
					</label>
				</div>
				<button
					className="UploadButton"
					disabled={!this.state.selectedFile || !this.state.termsChecked}
					onClick={this.doUpload}
				>
					Upload
					<span
						className="ProgressBar"
						style={{ width: `${this.state.progress}%` }}
					/>
				</button>
			</div>
		);
	}
}

export default Upload;
