import React, { Component } from 'react';
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Link } from "react-router-dom";

import './HourPreview.css';

class HourPreview extends Component {

	render() {
		const randomColor = `RandomColor_${Math.floor(Math.random() * 6.9999) + 1}`;
		return (
			<Link to={{
				pathname: `./${this.props.hour}`,
				// state: { hours: this.props.hour, minutes: this.props.minutes }
			}}>
				<div className={`HourPreview ${randomColor}`}>
					<h3>{this.props.hour}</h3>
					<div className="Minutes">
						{ this.props.minutes.map((val, idx) => <span key={idx} className={/*Math.random()>0.2*/ val ? "On" : "Off"}/>)}
					</div>
				</div>
			</Link>
		)
	}
}

export default HourPreview;
