import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import axios from "axios";

import Hour from "./components/Hour";
import Day from "./components/Day";
import Upload from "./components/Upload";
import Clock from "./components/Clock";

import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { hours: [{ hour: 0, minutes: [] }] };
  }

  loadStatus = () => {
    axios.get("/api/status").then((resp) => {
      if (resp.status === 200) {
        this.setState({ hours: resp.data });
      }
    });
  };

  componentDidMount() {
    this.loadStatus();
    this.interval = setInterval(() => this.loadStatus(), 10000); // every 10 seconds
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <Router>
        <div className="App">
          <header className="App-header">
            <h1>
              <Link to="/">Screen Time</Link>
            </h1>
            <p>
              <Link to="/">a community clock</Link>
            </p>
            <ul>
              <li>
                <NavLink exact to="/hour">
                  this hour
                </NavLink>
              </li>
              <li>
                <NavLink to="/day/">all hours</NavLink>
              </li>
              <li>
                <NavLink to="/upload/">upload</NavLink>
              </li>
              <li>
                <NavLink exact to="/">
                  about
                </NavLink>
              </li>
              <li>
                <NavLink to="/clock">the clock</NavLink>
              </li>
            </ul>
          </header>

          <Route exact path="/" component={About} />
          <Route
            exact
            path="/hour"
            render={(props) => <Hour hours={this.state.hours} {...props} />}
          />
          <Route path="/clock" component={Clock} />
          <Route path="/about" component={About} />
          <Route
            path="/day"
            render={(props) => <Day hours={this.state.hours} {...props} />}
          />
          <Route
            path="/upload/:hour?/:minute?"
            render={(props) => (
              <Upload refreshStatus={this.loadStatus} {...props} />
            )}
          />
        </div>
      </Router>
    );
  }
}

function About() {
  return (
    <div className="About">
      <p>
        Screen Time is a community clock project by{" "}
        <a
          href="http://www.helmutsmits.nl"
          target="_blank"
          rel="noopener noreferrer"
        >
          Helmut Smits
        </a>{" "}
        in collaboration with{" "}
        <a href="https://v2.nl/" target="_blank" rel="noopener noreferrer">
          V2_Lab for the Unstable Media
        </a>
        . Designed to connect strangers through their mobile backgrounds.
      </p>
      <p>To take part please follow these steps:</p>
      <ol>
        <li>
          Take a screenshot of your mobile device lock screen displaying the
          current time.
        </li>
        <li>
          Upload the screenshot to the allocated time slot of the minute it was
          taken.
        </li>
      </ol>
      <p>Thank you for your time!</p>
      <p className="FooterText">
        {/* Screen Time is supported by
        <br />
        The Mondriaan Fund and CBK Rotterdam */}
      </p>
    </div>
  );
}

export default App;
