import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./Clock.css";

class Clock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hour: -1,
			minute: -1,
			clockImage: `/images/notfound.jpg`,
			showClose: false,
			imageNotFound: false
		};
	}

	updateTime = () => {
		const now = new Date();
		if (
			this.state.hour === now.getHours() &&
			this.state.minute === now.getMinutes()
		) {
			return;
		}
		let h = `${now.getHours()}`.padStart(2, "0");
		let m = `${now.getMinutes()}`.padStart(2, "0");
		// h = "18";
		// m = "21";
		this.setState({
			clockImage: `/images/${h}/${h}${m}.jpg`,
			hour: now.getHours(),
			minute: now.getMinutes()
		});
	};

	clickClock = () => {
		if (!this.state.showClose) {
			if (this.clockTimer) {
				clearTimeout(this.clockTimer);
			}
			this.clockTimer = setTimeout(() => {
				this.setState({ showClose: false });
			}, 7000);
		}
		this.setState({ showClose: !this.state.showClose });
	};

	imageLoaded = evt => {
		// console.log('image loaded', evt);
		this.setState({ imageNotFound: false });
	};

	imageLoadError = () => {
		// console.log("image load error");
		this.setState({ imageNotFound: true, showClose: true });
	};

	componentDidMount() {
		this.updateTime();
		this.interval = setInterval(() => this.updateTime(), 1000); // every 1 seconds
	}

	componentWillUnmount() {
		clearInterval(this.interval);
		if (this.clockTimer) {
			clearTimeout(this.clockTimer);
		}
	}

	goBack = evt => {
		if (this.props.history.length <= 1) {
			this.props.history.push("/");
		} else {
			this.props.history.goBack();
		}
	};

	render() {
		const randomColor1 = `RandomColor_${Math.floor(Math.random() * 6.9999) +
			1}`;
		const randomColor2 = `RandomColor_${Math.floor(Math.random() * 6.9999) +
			1}`;
		return (
			<div
				className={`Clock ${this.state.imageNotFound ? "NoImageFound" : ""}`}
			>
				<span
					className={`Closer ${this.state.showClose ? "Show" : "Hide"}`}
					onClick={this.goBack}
				>
					<svg viewBox="0 0 38 37">
						<rect
							x="-0.14"
							y="15.55"
							transform="matrix(0.7071 0.7071 -0.7071 0.7071 18.6464 -8.0165)"
							width="38.28"
							height="5.89"
						/>
						<rect
							x="-0.14"
							y="15.55"
							transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 45.5165 18.1464)"
							width="38.28"
							height="5.89"
						/>
					</svg>
				</span>
				<div className="NoImageFoundInfo">
					<h2 className={randomColor1}>empty slot</h2>
					<p className={randomColor2}>
						<Link to="/upload">
							go to screentimeclock.net
							<br />
							to upload your lock screen
						</Link>
					</p>
				</div>
				<img
					className="ClockImage"
					alt="Clock"
					src={this.state.clockImage}
					onClick={this.clickClock}
					onLoad={this.imageLoaded}
					onError={this.imageLoadError}
				/>
			</div>
		);
	}
}

export default Clock;
