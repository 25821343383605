import React, { Component } from 'react';
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Route } from "react-router-dom";

import HourPreview from './HourPreview';
import Hour from './Hour';

import './Day.css';

class Day extends Component {

	render() {
		const hours = this.props.hours.map(hour => 
			<HourPreview key={hour.hour} hour={hour.hour} minutes={hour.minutes} />
			)
		return (
			<div>
				<Route path={`${this.props.match.path}/:hour`} render={props => <Hour {...props} hours={this.props.hours} />} />
				<Route
			        exact
			        path={this.props.match.path}
			        render={() => <div className="Day">{hours}</div>} 
			        />
		    </div>
			)
	}
}

export default Day;
