import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./Hour.css";

class Hour extends Component {
	constructor(props) {
		super(props);
		this.state = { largeImage: undefined };
	}

	showLarge = evt => {
		this.setState({ largeImage: evt.target.src });
	};
	hideLarge = () => {
		this.setState({ largeImage: undefined });
	};

	render() {
		var hour = new Date().getHours();
		if (
			this.props.match &&
			this.props.match.params &&
			this.props.match.params.hour
		) {
			hour = parseInt(this.props.match.params.hour);
		}
		var hourMins = [];
		if (this.props.hours && this.props.hours[hour]) {
			hourMins = this.props.hours[hour].minutes || [];
		}
		const h = `${hour}`.padStart(2, "0");
		const minutes = hourMins.map((hasScreen, minute) => {
			const m = `${minute}`.padStart(2, "0");
			const randomColor = `RandomColor_${Math.floor(Math.random() * 6.9999) +
				1}`;
			if (hasScreen) {
				return (
					<div key={m} className={`HasScreen ${randomColor}`}>
						<div>
							<img
								alt=""
								src={`/images/${h}/${h}${m}.jpg`}
								onClick={this.showLarge}
							/>
						</div>
					</div>
				);
			} else {
				return (
					<div key={m} className={randomColor}>
						<div>
							<Link to={`/upload/${h}/${m}`}>
								<span>
									{h}
									<br />:<br />
									{m}
								</span>
							</Link>
						</div>
					</div>
				);
			}
		});
		const prevH = `${(hour - 1 + 24) % 24}`.padStart(2, "0)");
		const nextH = `${(hour + 1 + 24) % 24}`.padStart(2, "0)");
		return (
			<div className="Hour">
				<div className="SubHeader">
					<Link to={`/day/${prevH}`}>
						<svg viewBox="0 0 25 37">
							<polygon points="18.51,2.77 22.52,6.78 10.5,18.8 21.91,30.22 17.91,34.23 2.48,18.8 " />
						</svg>{" "}
						{prevH}
					</Link>
					<h2>{h}: __ </h2>
					<Link to={`/day/${nextH}`}>
						{nextH}{" "}
						<svg viewBox="0 0 25 37">
							<polygon points="6.49,34.23 2.48,30.22 14.5,18.2 3.09,6.78 7.09,2.77 22.52,18.2 " />
						</svg>
					</Link>
				</div>
				<div className="Minutes">{minutes}</div>
				{this.state.largeImage && (
					<div className="LargeImage" onClick={this.hideLarge}>
						<img alt="large" src={this.state.largeImage} />
					</div>
				)}
			</div>
		);
	}
}

export default Hour;
